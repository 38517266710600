

import * as React from "react"
import { useStaticQuery, graphql } from "gatsby";
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../../../Layout"
import Seo from "../../../components/Seo/Seo"
import Breadcrumb from '../../../components/Breadcrumb/Breadcrumb'
import PageBanner from "../../../components/PageBanner/PageBanner";
import TitleSection from '../../../Molecules/TitleSection/TitleSection'
import TitleBlock from '../../../Molecules/TitleBlock/TitleBlock'
import KeyNumbers from '../../../components/KeyNumbers/KeyNumbers'
import ShapeBackground from '../../../Molecules/ShapeBackground/ShapeBackground';
import ScrollPageNav from '../../../components/ScrollPageNav/ScrollPageNav'
import { Button } from '../../../Molecules/Button/Button'

import TextVisuelTwoCol from '../../../components/ContentType/TextVisuelTwoCol/TextVisuelTwoCol'
import VisuelTextOverlappedBlock from '../../../components/ContentType/VisuelTextOverlappedBlock/VisuelTextOverlappedBlock'

import TitlePage from '../../../Molecules/TitlePage/TitlePage'

import './styles.scss'

var classNames = require('classnames');


const OurStrategyPage = () => {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: {eq: "slider-image.png"}) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);
  return (
    <Layout>
      <Seo
        title="Colas About US"
        description="Description de la page About us"
      />
      <div className={classNames('page_template', 'zero_bottom_padding', 'our_strategy_page')}>
        <ScrollPageNav
          data={[
            {
              title: 'Notre mission',
              section: 'intro_explication_component'
            },
            {
              title: 'Notre raison d’agir',
              section: 'text_visuel_reason_action'
            },
            {
              title: 'Notre ambition',
              section: 'our_ambition'
            },
            {
              title: 'Nos valeurs',
              section: 'our_values'
            },
            {
              title: 'Notre origine',
              section: 'our_origin'
            },
            {
              title: 'Les chiffres clefs',
              section: 'key_numbers_component'
            }
          ]}
        />
        <PageBanner visuel={data.file.childImageSharp.fluid} scroll={true}>
          <div className="wrapper_page">
            <Breadcrumb
              page_banner={true}
              data={{
                grandParentPage: { title: "Acceuil", url: "/" },
                parentPage: { title: "Groupe", url: "/groupe" },
                currentPage: { title: "Qui somme nous", url: "/about-us" }
              }}
            />
            <TitlePage
              color="color_white"
              title="Notre stratégie"
            />
          </div>
        </PageBanner>

        <section className={classNames('section_content', 'section_developement')}>
          <div className="wrapper_page">
            <TitleSection
              title="Notre stratégie de développement"
              description="La stratégie de développement de Colas se décline en quatre axes principaux :"
              type="line"
            />
            <TextVisuelTwoCol
              orderInverse={false}
              extremeLeft={true}
              visuel={data.file.childImageSharp}
              title="Test Title"
              text="1 Favoriser les contacts humains, les échanges culturels et la vie économique des territoires : c’est notre raison d’agir depuis la création de Colas. Les routes, les voies urbaines, les voies ferrées, les ports et les aéroports constituent les premiers réseaux sociaux. En œuvrant à leur construction et à leur entretien, nous contribuons à relier les hommes et les territoires et à faciliter les échanges. "
            />
            <TextVisuelTwoCol
              orderInverse={true}
              extremeRight={true}
              visuel={data.file.childImageSharp}
              title="Test Title"
              text="1 Favoriser les contacts humains, les échanges culturels et la vie économique des territoires : c’est notre raison d’agir depuis la création de Colas. Les routes, les voies urbaines, les voies ferrées, les ports et les aéroports constituent les premiers réseaux sociaux. En œuvrant à leur construction et à leur entretien, nous contribuons à relier les hommes et les territoires et à faciliter les échanges. "
            />
            <TextVisuelTwoCol
              orderInverse={false}
              extremeLeft={true}
              visuel={data.file.childImageSharp}
              title="Test Title"
              text="1 Favoriser les contacts humains, les échanges culturels et la vie économique des territoires : c’est notre raison d’agir depuis la création de Colas. Les routes, les voies urbaines, les voies ferrées, les ports et les aéroports constituent les premiers réseaux sociaux. En œuvrant à leur construction et à leur entretien, nous contribuons à relier les hommes et les territoires et à faciliter les échanges. "
            />
            <TextVisuelTwoCol
              orderInverse={true}
              extremeRight={true}
              visuel={data.file.childImageSharp}
              title="9 Test Title"
              text="1 Favoriser les contacts humains, les échanges culturels et la vie économique des territoires : c’est notre raison d’agir depuis la création de Colas. Les routes, les voies urbaines, les voies ferrées, les ports et les aéroports constituent les premiers réseaux sociaux. En œuvrant à leur construction et à leur entretien, nous contribuons à relier les hommes et les territoires et à faciliter les échanges. "
            />
            <TextVisuelTwoCol
              orderInverse={false}
              extremeLeft={true}
              visuel={data.file.childImageSharp}
              title="Test Title"
              text="1 Favoriser les contacts humains, les échanges culturels et la vie économique des territoires : c’est notre raison d’agir depuis la création de Colas. Les routes, les voies urbaines, les voies ferrées, les ports et les aéroports constituent les premiers réseaux sociaux. En œuvrant à leur construction et à leur entretien, nous contribuons à relier les hommes et les territoires et à faciliter les échanges. "
            />
          </div>
        </section>

        <section className={classNames('section_content', 'section_climate_issues')}>
          <ShapeBackground
            top="left"
            color="catskill_white"
            bottomInfinite={true}
          >
            <div className="full_page_wrapper">
              <div className="wrapper_page_xs">
                <TitleSection
                  title="Intégration des enjeux climatiques dans la stratégie"
                  type="arrow"
                  h2color='color_dark_bleu'
                />
              </div>
              <VisuelTextOverlappedBlock
                visuel={data.file.childImageSharp}
                bgColor='color_dark_bleu'
                alt=""
                text="Face aux enjeux du changement climatique, Colas est engagé dans une stratégie de décarbonation de ses activités et s’est fixé un objectif ambitieux, compatible avec l’Accord de Paris : réduire, d’ici à 2030, de 30 % ses émissions directes de gaz à effet de serre (scopes 1 et 2) et de 30 % ses émissions indirectes en amont (scope 3a) par rapport à 2019, année de référence. Cet objectif s’appuie sur des estimations d’économies de CO2 résultant de la mise en place d’une série d’actions consignées dans une feuille de route."
              />
              <div className="wrapper_page_xs">
                <div className="free_highlight_text">
                  <p>
                    Pilotée par un Comité stratégie bas carbone et déployée dans les entités opérationnelles, la feuille de route bas carbone de Colas comporte 24 engagements, suivis par des indicateurs existants ou en cours de création. Les actions mises en place s’ordonnent autour de quatre axes :
                  </p>
                </div>
                <TextVisuelTwoCol
                  orderInverse={true}
                  extremeRight={true}
                  visuel={data.file.childImageSharp}
                  title="Test Title"
                  text="1 Favoriser les contacts humains, les échanges culturels et la vie économique des territoires : c’est notre raison d’agir depuis la création de Colas. Les routes, les voies urbaines, les voies ferrées, les ports et les aéroports constituent les premiers réseaux sociaux. En œuvrant à leur construction et à leur entretien, nous contribuons à relier les hommes et les territoires et à faciliter les échanges. "
                />
                <TextVisuelTwoCol
                  orderInverse={false}
                  extremeLeft={true}
                  visuel={data.file.childImageSharp}
                  title="Test Title"
                  text="1 Favoriser les contacts humains, les échanges culturels et la vie économique des territoires : c’est notre raison d’agir depuis la création de Colas. Les routes, les voies urbaines, les voies ferrées, les ports et les aéroports constituent les premiers réseaux sociaux. En œuvrant à leur construction et à leur entretien, nous contribuons à relier les hommes et les territoires et à faciliter les échanges. "
                />
                <TextVisuelTwoCol
                  orderInverse={true}
                  extremeRight={true}
                  visuel={data.file.childImageSharp}
                  title="Test Title"
                  text="1 Favoriser les contacts humains, les échanges culturels et la vie économique des territoires : c’est notre raison d’agir depuis la création de Colas. Les routes, les voies urbaines, les voies ferrées, les ports et les aéroports constituent les premiers réseaux sociaux. En œuvrant à leur construction et à leur entretien, nous contribuons à relier les hommes et les territoires et à faciliter les échanges. "
                />
              </div>
            </div>
          </ShapeBackground>
        </section>

        <section className={classNames('section_content', 'section_force_astuce')}>
          <ShapeBackground
            top="left"
            bottomInfinite={true}
          >
            <div className="wrapper_page">
              <TitleSection
                title="Forces et atouts"
                type="arrow"
                h2color="white"
              />
              <VisuelTextOverlappedBlock
                visuel={data.file.childImageSharp}
                bgColor='color_sky_bleu'
                extremeLeft={true}
                alt=""
                text="Colas bénéficie de marchés porteurs sur le long terme dans ses pays d’implantation. Ces marchés sont tirés par la croissance démographique, l’urbanisation des territoires, des besoins importants d’équipement dans les pays émergents, des besoins récurrents d’entretien dans les pays développés, les enjeux environnementaux, le développement de nouvelles formes de mobilité et la révolution numérique. Les principaux atouts de Colas sont au nombre de cinq."
              />
              <TextVisuelTwoCol
                orderInverse={true}
                visuel={data.file.childImageSharp}
                colorTitle="color_white"
                colorDescription="color_white"
                title="Sa capacité d'innovation"
                titleBackground={true}
                extactHalf={true}
                text="1 Favoriser les contacts humains, les échanges culturels et la vie économique des territoires : c’est notre raison d’agir depuis la création de Colas. Les routes, les voies urbaines, les voies ferrées, les ports et les aéroports constituent les premiers réseaux sociaux. En œuvrant à leur construction et à leur entretien, nous contribuons à relier les hommes et les territoires et à faciliter les échanges. "
              />
              <TextVisuelTwoCol
                orderInverse={false}
                visuel={data.file.childImageSharp}
                colorTitle="color_white"
                colorDescription="color_white"
                title="Son expertise <br/>collective mondiale"
                titleBackground={true}
                extactHalf={true}
                text="1 Favoriser les contacts humains, les échanges culturels et la vie économique des territoires : c’est notre raison d’agir depuis la création de Colas. Les routes, les voies urbaines, les voies ferrées, les ports et les aéroports constituent les premiers réseaux sociaux. En œuvrant à leur construction et à leur entretien, nous contribuons à relier les hommes et les territoires et à faciliter les échanges. "
              />
              <TextVisuelTwoCol
                orderInverse={true}
                visuel={data.file.childImageSharp}
                colorTitle="color_white"
                colorDescription="color_white"
                title="Sa capacité d'innovation"
                titleBackground={true}
                extactHalf={true}
                text="1 Favoriser les contacts humains, les échanges culturels et la vie économique des territoires : c’est notre raison d’agir depuis la création de Colas. Les routes, les voies urbaines, les voies ferrées, les ports et les aéroports constituent les premiers réseaux sociaux. En œuvrant à leur construction et à leur entretien, nous contribuons à relier les hommes et les territoires et à faciliter les échanges. "
              />
            </div>
          </ShapeBackground>
        </section>

      </div>
    </Layout>
  )

}

export default OurStrategyPage
